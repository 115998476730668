.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-con{
    display: flex;
    gap:3rem;
    background-color: gray;
    padding: 1rem 2rem;
}
.email-con>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
}
::placeholder{
    color: var(--lightgray);
}
.btn-j{
    color: white;
    background-color: var(--orange);
}